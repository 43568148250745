import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import globalStyle from "../styles/global.module.css"
import becomeAMemberStyle from "./become-a-member.module.css"
import {
  Col,
  Row,
  Container,
  Button,
  Form,
  Alert,
  Card,
  CardDeck,
} from "react-bootstrap"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import env from "../config/env"
import axios from "axios"
// import addToMailchimp from "gatsby-plugin-mailchimp"
import addToMailchimp from "../services/mailchimp";
import formStyle from "./form.module.css"
import VideoAudioControl from "../components/videoAudioControl"
import MembershipSkuCard from "../components/Products/membershipSkuCard";

const openPaywallNConfig = {
  action: env.openPaywallNAction,
  accessToken: env.GATSBY_STRIPE_PUBLIC_KEY,
  cost: env.vipMemberCost,
};

const useStateWithLocalStorageForEmail = localStorageKey => {
  //get email value from localStorage
  const [emailValueFromLocalStorage, setEmailValueFromLocalStorage] = useState(
    (typeof window !== "undefined" && localStorage.getItem(localStorageKey)) ||
      ""
  );

  //set user email value in localStorage
  useEffect(() => {
    localStorage.setItem(localStorageKey, emailValueFromLocalStorage)
  }, [emailValueFromLocalStorage])

  return [emailValueFromLocalStorage, setEmailValueFromLocalStorage]
};

const useStateWithLocalStorageForLast4 = localStorageKey => {
  const [last4ValueFromLocalStorage, setLast4ValueFromLocalStorage] = useState(
    (typeof window !== "undefined" && localStorage.getItem(localStorageKey)) ||
      ""
  );

  useEffect(() => {
    localStorage.setItem(localStorageKey, last4ValueFromLocalStorage)
  }, [last4ValueFromLocalStorage]);

  return [last4ValueFromLocalStorage, setLast4ValueFromLocalStorage]
}

const BecomeAMamberPage = ({ data }) => {
  const [
    emailValueFromLocalStorage,
    setEmailValueFromLocalStorage,
  ] = useStateWithLocalStorageForEmail("email")
  const [
    last4ValueFromLocalStorage,
    setLast4ValueFromLocalStorage,
  ] = useStateWithLocalStorageForLast4("last4")

  let [showSignUpForm, setShowSignUpForm] = useState({
    showTop: false,
    showBottom: false,
  })
  let [showLoginFormContent, setShowLoginFormContent] = useState({
    showTop: false,
    showBottom: false,
  })

  const [formState, setFormValues] = useState({ email: "", last4: "", size: "" })
  const [stripeShowError, setStripeShowErrorAlert] = useState({
    show: false,
    message: "",
  })
  const [
    stripeShowErrorBottomForm,
    setStripeShowErrorBottomFormAlert,
  ] = useState({ show: false, message: "" })
  const [stripeShowSuccessMessage, setStripeShowSuccessAlert] = useState({
    show: false,
    message: "",
  })
  const [
    stripeShowSuccessMessageBottomForm,
    setStripeShowSuccessAlertBottomForm,
  ] = useState({ show: false, message: "" })
  const [memberExistsAlert, setMemberExistsAlert] = useState({
    show: false,
    message: "",
  })
  const [formResult, setFormResult] = useState({})
  const [showError, setShowErrorAlert] = useState({
    showTop: false,
    showBottom: false,
    onField: "",
  })
  const [
    mailchimpAddMemberShowError,
    setMailchimpAddMemberShowErrorAlert,
  ] = useState({ show: false, message: "" })
  const [
    mailchimpAddMemberShowSuccessMessage,
    setMailchimpAddMemberShowSuccessMessageAlert,
  ] = useState({ show: false })
  const [
    loggingIsBlocked,
    setLoggingIsBlocked
  ] = useState(false)
  const [
    showLoginAlert,
    setShowLoginAlert
  ] = useState({showTop: false, showBottom: false, message: ""})


  const submitVIPMemberUrl = env.submitVIPMemberUrl
  const VIPMembersUrl = env.VIPMembersUrl
  const [sku, setSku] = useState(null);

  const [showSizeError, setShowSizeErrorAlert] = useState({showTop: false, showBottom: false});
  const [showEmailError, setShowEmailErrorAlert] = useState({showTop: false, showBottom: false});

  useEffect(() => {
    if (last4ValueFromLocalStorage && emailValueFromLocalStorage) {
      setShowSignUpForm({ showTop: false, showBottom: false })
    }
  }, [])

  useEffect(() => {
    if (
      emailValueFromLocalStorage &&
      last4ValueFromLocalStorage &&
      typeof window !== "undefined"
    ) {
      window.location.replace("/member-lounge")
    }
  }, [emailValueFromLocalStorage, last4ValueFromLocalStorage])

  //submit email form
  const _handleSubmit = async e => {
    e.preventDefault()
    try {
      const isSignIn = "signin" === e.nativeEvent.target.name
      const requiredFields = ["email"]
      if (isSignIn) {
        requiredFields.push("last4")
      }
      for (const field in formState) {
        if (requiredFields.indexOf(field) !== -1) {
          if (!formState[field]) {
            if (showLoginFormContent.showTop === true) {
              setShowErrorAlert({
                showTop: true,
                onField: field.charAt(0).toUpperCase() + field.slice(1),
              })
            } else {
              setShowErrorAlert({
                showBottom: true,
                onField: field.charAt(0).toUpperCase() + field.slice(1),
              })
            }
            return
          }
        }
      }

      if (loggingIsBlocked) {
        return
      }
      setLoggingIsBlocked(true)
      // check if an email and last4 combo exists in the VIPMember list
      const response = await axios({
        method: "get",
        url: VIPMembersUrl + formState.email + "/" + formState.last4,
        headers: {
          "Access-Control-Allow-Origin": window.location.origin,
        },
      })

      setLoggingIsBlocked(false)

      //if email address does not exist in VIP Members list then hide email form and show payment form
      if (response.data.memberExists === false) {
        if (showLoginFormContent.showTop === true) {
          setShowLoginAlert({
            showTop: true,
            showBottom: false,
            message: "You are not a MOM member. Please Join Us!"
          });
        } else {
          setShowLoginAlert({
            showTop: false,
            showBottom: true,
            message: "You are not a MOM member. Please Join Us!"
          });
        }



        setShowLoginFormContent({ showTop: false, showBottom: false })
        setShowSignUpForm({ showTop: false, showBottom: false })
      } else {
        // if user email already exist in the list show the content
        setMemberExistsAlert({
          show: true,
          message: isSignIn
            ? "You are now logged in!"
            : "You are already a member. Sign in!",
        })
        setShowLoginFormContent({ showTop: false, showBottom: false })
        setShowSignUpForm({ showTop: false, showBottom: false })

        if (isSignIn) {
          setEmailValueFromLocalStorage(formState.email)
          setLast4ValueFromLocalStorage(formState.last4)
        }
      }
    } catch (error) {
      console.log(error)
      setLoggingIsBlocked(false)
    }
  }

  //when payment is complete, save email and last4 card no to mailchimp
  const onPaymentCompleted = async (size, email, onPaymentCompleted) => {
    try {
      if (onPaymentCompleted.status == 200) {
        // setEmailValueFromLocalStorage(email);
        // setLast4ValueFromLocalStorage(onPaymentCompleted.data.response.payment_method_details.card.last4);

        const result = await addToMailchimp(
          email,
          {
            LAST4:
              onPaymentCompleted.data.response.payment_method_details.card
                .last4,
            SIZE: size,
          },
          submitVIPMemberUrl
        )

        if (result.result === "error") {
          setMailchimpAddMemberShowErrorAlert({
            show: true,
            message: result.msg,
          })
        } else {
          setEmailValueFromLocalStorage(email)
          setLast4ValueFromLocalStorage(
            onPaymentCompleted.data.response.payment_method_details.card.last4
          )
          setFormResult(result)
          setShowLoginFormContent({ showTop: false, showBottom: false })
          setShowSignUpForm({ showTop: false, showBottom: false })
          setMailchimpAddMemberShowSuccessMessageAlert({ show: true })
          if (showSignUpForm.showTop === true) {
            setStripeShowErrorAlert({ show: false, message: "" })
          } else {
            setStripeShowErrorBottomFormAlert({ show: false, message: "" })
          }

          setMailchimpAddMemberShowErrorAlert({ show: false, message: "" })
        }
      }
    } catch (error) {
      if (error) {
        console.log(error.message)
      }
    }
  };

  const showErrorMessage = showErrorMessage => {
    if (showSignUpForm.showTop === true) {
      setStripeShowErrorAlert({
        show: true,
        message: showErrorMessage,
      })
    } else {
      setStripeShowErrorBottomFormAlert({
        show: true,
        message: showErrorMessage,
      })
    }
  };

  const showSuccessMessage = message => {
    if (message) {
      if (showSignUpForm.showTop === true) {
        setStripeShowSuccessAlert({
          show: true,
          message: message,
        })
      } else {
        setStripeShowErrorBottomFormAlert({
          show: true,
          message: message,
        })
      }
    }
  };

  const handleSignUpSubmit = async e => {
    e.preventDefault();
    try {
      const requiredFields = ["email", "size"];

      for (const field in formState) {
        if (requiredFields.indexOf(field) !== -1) {
          if (!formState[field]) {
            setShowErrorAlert({
              showTop: true,
              onField: field.charAt(0).toUpperCase() + field.slice(1),
            })
          } else {
            setShowErrorAlert({
              showBottom: true,
              onField: field.charAt(0).toUpperCase() + field.slice(1),
            })
          }
          return
        }
      }

      if (loggingIsBlocked) {
        return
      }
      setLoggingIsBlocked(true);
      // check if an email and last4 combo exists in the VIPMember list
      const response = await axios({
        method: "get",
        url: VIPMembersUrl + formState.email + "/" + formState.last4,
        headers: {
          "Access-Control-Allow-Origin": window.location.origin,
        },
      });

      if (response.data.memberExists === true) {
        // if user email already exist in the list show the content
        setMemberExistsAlert({
          show: true,
          message: "You are now logged in!"
        })
      }
      setLoggingIsBlocked(false);
    } catch (e) {
      console.log(e)
    }
  };

  const validateForm = async (e) => {
    let isValid = true;

    setShowSizeErrorAlert({showTop: false, showBottom: false});
    setShowEmailErrorAlert({showTop: false, showBottom: false});

    if(!formState.size) {
      isValid = false;
      if (showSignUpForm.showTop) {

        setShowSizeErrorAlert({showTop: true})
      } else {
        setShowSizeErrorAlert({showBottom: true})
      }
    }

    if(!formState.email) {
      isValid = false;
      if (showSignUpForm.showTop) {
        setShowEmailErrorAlert({showTop: true})
      } else {
        setShowEmailErrorAlert({showBottom: true})
      }
    }

    if (isValid) {
      const response = await axios({
        method: "get",
        url: VIPMembersUrl + formState.email + "/" + formState.last4,
        headers: {
          "Access-Control-Allow-Origin": window.location.origin,
        },
      });

      if (response.data.memberExists === true) {
        isValid = false;
        // if user email already exist in the list show the content
        setMemberExistsAlert({
          show: true,
          message: "You are a MOM member already! Please login!"
        })
      }
    } else {
      return;
    }

    return isValid
  };

  const showStripeErrorMessage = showStripeErrorMessage => {
    if (showSignUpForm.showTop === true) {
      setStripeShowErrorAlert({
        show: true,
        message: showStripeErrorMessage,
      })
    } else {
      setStripeShowErrorBottomFormAlert({
        show: true,
        message: showStripeErrorMessage,
      })
    }
  };

  const handleFormChange = e => {
    setFormValues({
      ...formState,
      [e.target.name]: e.target.value,
    })
  };

  const handleSignUpFormChange = e => {
    setFormValues({
      ...formState,
      [e.target.name]: e.target.value,
    });
    setShowEmailErrorAlert({showTop: false, showBottom: false});
    setShowSizeErrorAlert({showTop: false, showBottom: false});
    setStripeShowErrorBottomFormAlert({ show: false });
  };

  const handleSizeSelection = (e) => {
    handleSignUpFormChange(e);
    data.skus.edges.map(edge => {
      let productName = "Membership";
      if (productName === edge.node.product.name
        && edge.node.attributes.size === e.target.value
      ) {
        setSku(edge.node);
      }
    });
  };

  return (
    <Layout className={becomeAMemberStyle.becomeAMemberContent}>
      <Container>
        <Row className={becomeAMemberStyle.memberBanner}>
          <Col>
            <h1 className={globalStyle.entryTitle}>JOIN US</h1>
            <h4 className={becomeAMemberStyle.description}>
              Take care of you, Mama! We have found when you feel good, your
              family and work feels good too. Enter the Member Lounge. It’s a $450 value for only $49!
            </h4>
          </Col>
        </Row>

        <Row>
          <Col md={4} sm={4} xs={12}>
            <Row className={becomeAMemberStyle.bannerContent}>
              <Col className={becomeAMemberStyle.bannerColumn}>
                <Row className={becomeAMemberStyle.price}>
                  <Col>
                    <p> Member Benefits </p>
                  </Col>
                </Row>

                <Row className={becomeAMemberStyle.memberBenefitsVideo}>
                  <h4 className={becomeAMemberStyle.description}>
                    Unlimited access +
                    <Link
                      className={becomeAMemberStyle.redirectLink}
                      to={"/shop/clothes/"}
                    >
                      {" "}
                      MOM Tank Top{" "}
                    </Link>
                  </h4>
                  {data.membershipBenefitsVideo.edges.map(item => (
                    <Col
                      key={item.node.id}
                      id={item.node.id}
                      xs={12}
                      md={12}
                      sm={12}
                      className={becomeAMemberStyle.benefitsVideoContent}
                    >
                      <iframe
                        id={item.node.id}
                        src={
                          "https://player.vimeo.com/video/" +
                          item.node.id +
                          "?autoplay=1;muted=1;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=163410"
                        }
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen
                        title="Membership Benefits Video"
                        controls
                        controlsList="nodownload"
                        mozallowfullscreen="true"
                        allowFullScreen
                      ></iframe>
                      <VideoAudioControl></VideoAudioControl>
                    </Col>
                  ))}
                </Row>

                {!(
                  emailValueFromLocalStorage && last4ValueFromLocalStorage
                ) && (
                  <>
                    <Row>
                      <Col>
                        <p className={globalStyle.boldStyle}>
                          Already a member?
                        </p>
                        <Button
                          className={[
                            globalStyle.btnPrimary,
                            becomeAMemberStyle.goldButton,
                          ]}
                          onClick={() => {
                            setShowLoginFormContent({
                              showTop: !showLoginFormContent.showTop,
                              showBottom: false,
                            })
                            setShowSignUpForm({
                              showTop: false,
                              showBottom: false,
                            })
                            setShowErrorAlert({
                              showTop: false,
                              showBottom: false,
                            })
                            setShowLoginAlert({
                              showTop: false,
                              showBottom: false,
                            })
                            setShowSizeErrorAlert({
                              showTop: false,
                              showBottom: false
                            })
                            setShowEmailErrorAlert({
                              showTop: false,
                              showBottom: false
                            })
                          }}
                        >
                          SIGN IN
                        </Button>
                      </Col>
                    </Row>

                    {showLoginAlert.showTop && (
                      <Row className={becomeAMemberStyle.showStripeError}>
                        <Alert
                          variant="danger"
                          onClose={() => setShowLoginAlert({ showTop: false })}
                          dismissible
                        >
                          {showLoginAlert.message}
                        </Alert>
                      </Row>
                    )}

                    <Row>
                      <Col>
                        <p className={becomeAMemberStyle.promoChoice}>OR</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Button
                          className={[
                            globalStyle.btnPrimary,
                            becomeAMemberStyle.goldButton,
                          ]}
                          onClick={() => {
                            setShowLoginFormContent({
                              showTop: false,
                              showBottom: false,
                            })
                            setShowSignUpForm({
                              showTop: !showSignUpForm.showTop,
                              showBottom: false,
                            })
                            setShowErrorAlert({
                              showTop: false,
                              showBottom: false,
                            })
                            setShowLoginAlert({
                              showTop: false,
                              showBottom: false,
                            })
                            setShowSizeErrorAlert({
                              showTop: false,
                              showBottom: false
                            })
                            setShowEmailErrorAlert({
                              showTop: false,
                              showBottom: false
                            })
                          }}
                        >
                          JOIN US AND GET IMMEDIATE ACCESS
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}

                <Row className={becomeAMemberStyle.formContent}>
                  {showLoginFormContent.showTop && (
                    <Form onSubmit={_handleSubmit} name="signin">
                      <Form.Row>
                        <Col xs={12} md={12} sm={12}>
                          <Form.Group as={Col} controlId="formEmail">
                            <Form.Control
                              size="sm"
                              value={formState.email}
                              name="email"
                              type="text"
                              placeholder="Please enter your email"
                              onChange={handleFormChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={12} sm={12}>
                          <Form.Group as={Col} controlId="formLast4">
                            <Form.Control
                              size="sm"
                              value={formState.last4}
                              name="last4"
                              type="text"
                              placeholder="Last 4 digits of your payment card"
                              onChange={handleFormChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={12} sm={12}>
                          <Button
                            type="submit"
                            className={[
                              formStyle.btnPrimary,
                              formStyle.submitButtonOrange,
                              becomeAMemberStyle.submitButton,
                            ]}
                          >
                            Log in
                          </Button>
                        </Col>
                      </Form.Row>
                    </Form>
                  )}
                </Row>

                {showSignUpForm.showTop && (
                  <Form onSubmit={handleSignUpSubmit} name="register">
                    <Form.Row>
                      <Col xs={12} md={12} sm={12}>
                        <Form.Group as={Col} className={becomeAMemberStyle.registerFormGroup} controlId="formLast4">
                          <Form.Control as="select" onChange={handleSizeSelection} value={formState.size}
                                        placeholder="Select your MOM Tank Size" name="size">
                            <option className="defaultValue" value="">Select your MOM Tank size</option>
                            <option value="S">S</option>
                            <option value="M">M</option>
                            <option value="L">L</option>
                            <option value="XL">XL</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      <Col xs={12} md={12} sm={12}>
                        <Form.Group as={Col} controlId="formEmail" className={becomeAMemberStyle.registerFormGroup}>
                          <Form.Control
                            size="sm"
                            value={formState.email}
                            name="email"
                            type="text"
                            placeholder="Please enter your email"
                            onChange={handleSignUpFormChange}
                          />
                        </Form.Group>
                      </Col>

                      <Col xs={12} md={12} sm={12}>
                        <MembershipSkuCard quantity={formState.quantity}
                                 email={formState.email}
                                 size={formState.size}
                                 sku={sku}
                                 beforeRedirect={validateForm}
                                 showStripeErrorMessage={showStripeErrorMessage}
                                 path="/become-a-member"
                        />
                      </Col>
                    </Form.Row>
                  </Form>
                )}

                {
                  showSizeError.showTop &&
                  <Row>
                    <Col>
                      <Alert className={"mt-2"} variant="danger" onClose={() => setShowSizeErrorAlert({showTop: false})} dismissible
                      >Please select your MOM Tank size!</Alert>
                    </Col>
                  </Row>
                }

                {
                  showEmailError.showTop &&
                  <Row>
                    <Col>
                      <Alert className={"mt-2"} variant="danger" onClose={() => setShowEmailErrorAlert({showTop: false})} dismissible
                      >Please enter your email address!</Alert>
                    </Col>
                  </Row>
                }

                {showError.showTop && (
                  <Row className={becomeAMemberStyle.showStripeError}>
                    <Alert
                      variant="danger"
                      onClose={() => setShowErrorAlert({ showTop: false })}
                      dismissible
                    >
                     Please fill the {showError.onField.toLowerCase()} field!
                    </Alert>
                  </Row>
                )}
                {stripeShowError.show && (
                  <Row className={becomeAMemberStyle.showStripeError}>
                    <Alert
                      variant="danger"
                      onClose={() => setStripeShowErrorAlert({ show: false })}
                      dismissible
                    >
                      {stripeShowError.message}
                    </Alert>
                  </Row>
                )}

                {stripeShowSuccessMessage.show && (
                  <Row className={becomeAMemberStyle.showStripeError}>
                    <Alert
                      variant="success"
                      onClose={() => setStripeShowSuccessAlert({ show: false })}
                      dismissible
                    >
                      {stripeShowSuccessMessage.message}
                    </Alert>
                  </Row>
                )}

                {mailchimpAddMemberShowError.show && (
                  <Row className={becomeAMemberStyle.showMemberExistsAlert}>
                    <Alert
                      variant="danger"
                      onClose={() =>
                        setMailchimpAddMemberShowErrorAlert({ show: false })
                      }
                      dismissible
                    >
                      {/*{mailchimpAddMemberShowError.message}*/}
                      Please try again!
                    </Alert>
                  </Row>
                )}

                {memberExistsAlert.show && (
                  <Row className={becomeAMemberStyle.showMemberExistsAlert}>
                    <Alert
                      variant="primary"
                      onClose={() => setMemberExistsAlert({ show: false })}
                      dismissible
                    >
                      {memberExistsAlert.message}
                    </Alert>
                  </Row>
                )}

                {mailchimpAddMemberShowSuccessMessage.show && (
                  <Row className={becomeAMemberStyle.showMemberExistsAlert}>
                    <Alert
                      variant="primary"
                      onClose={() =>
                        setMailchimpAddMemberShowSuccessMessageAlert({
                          show: false,
                        })
                      }
                      dismissible
                    >
                      Thank you for subscribing! You are a MOM Member!
                    </Alert>
                  </Row>
                )}

                <Row className={becomeAMemberStyle.price}>
                  <Col>
                    <p>$49 UNLIMITED ACCESS</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col md={8} sm={8} xs={12}>
            <Row className={becomeAMemberStyle.firstRow}>
              <Col md={12} sm={12} xs={12}>
                <CardDeck>
                  <Card className={becomeAMemberStyle.cardContent}>
                    <Card.Body>
                      <Card.Title>
                        <h5 className={becomeAMemberStyle.benefitsTitle}>
                          Prenatal Yoga
                        </h5>
                      </Card.Title>
                      <Link to={"/membership-preview"}>
                        <Img
                          fluid={data.prenatalYoga.childImageSharp.fluid}
                        ></Img>
                      </Link>
                      <Card.Text>
                        <span className={becomeAMemberStyle.aboutContent}>
                          Members get full access to the following online Yoga
                          Classes. Watch and do your yoga whenever you want!
                        </span>
                      </Card.Text>
                      <ul className={becomeAMemberStyle.list}>
                        <li>
                          <Link to={"/membership-preview"}>
                            Modified flow class
                          </Link>
                        </li>
                        <li>
                          <Link to={"/membership-preview"}>
                            Modified prenatal vinyasa
                          </Link>
                        </li>
                        <li>
                          <Link to={"/membership-preview"}>
                            Prenatal for the spine yoga
                          </Link>
                        </li>
                        <li>
                          <Link to={"/membership-preview"}>
                            Easing leg cramps class
                          </Link>
                        </li>
                        <li>
                          <Link to={"/membership-preview"}>
                            Preparing for birth
                          </Link>
                        </li>
                      </ul>
                    </Card.Body>
                    <Card.Footer className={becomeAMemberStyle.cardFooter}>
                      <div className={becomeAMemberStyle.previewButtonContent}>
                        <Button
                          className={[
                            globalStyle.btnPrimary,
                            globalStyle.submitButtonOrange,
                            becomeAMemberStyle.previewButton,
                          ]}
                          href="/membership-preview"
                        >
                          Preview
                        </Button>
                      </div>
                    </Card.Footer>
                  </Card>

                  <Card className={becomeAMemberStyle.cardContent}>
                    <Card.Body>
                      <Card.Title className="blogTitle">
                        <h5 className={becomeAMemberStyle.benefitsTitle}>
                          Postpartum Yoga
                        </h5>
                      </Card.Title>
                      <Link to={"/membership-preview"}>
                        <Img
                          fluid={data.postpartumYoga.childImageSharp.fluid}
                        ></Img>
                      </Link>
                      <Card.Text className="cardText">
                        <span className={becomeAMemberStyle.aboutContent}>
                          Members get full access to the following online Yoga
                          Classes. Watch and do your yoga whenever you want!
                        </span>
                      </Card.Text>
                      <ul className={becomeAMemberStyle.list}>
                        <li>
                          <Link to={"/membership-preview"}>
                            Back to the mat
                          </Link>
                        </li>
                        <li>
                          <Link to={"/membership-preview"}>Post caesarean</Link>
                        </li>
                        <li>
                          <Link to={"/membership-preview"}>
                            Baby and me for energy and joy
                          </Link>
                        </li>
                        <li>
                          <Link to={"/membership-preview"}>
                            Mom and baby move your body
                          </Link>
                        </li>
                      </ul>
                    </Card.Body>
                    <Card.Footer className={becomeAMemberStyle.cardFooter}>
                      <div className={becomeAMemberStyle.previewButtonContent}>
                        <Button
                          className={[
                            globalStyle.btnPrimary,
                            globalStyle.submitButtonOrange,
                            becomeAMemberStyle.previewButton,
                          ]}
                          href="/membership-preview"
                        >
                          Preview
                        </Button>
                      </div>
                    </Card.Footer>
                  </Card>
                </CardDeck>
              </Col>
            </Row>

            <hr className={becomeAMemberStyle.bottomLine} />

            <Row>
              <Col md={12} sm={12} xs={12}>
                <CardDeck>
                  <Card className={becomeAMemberStyle.cardContent}>
                    <Card.Body>
                      <Card.Title className="blogTitle">
                        <h5 className={becomeAMemberStyle.benefitsTitle}>
                          Mom Meditations
                        </h5>
                      </Card.Title>
                      <Link to="/membership-preview">
                        <Img
                          fluid={data.momMeditations.childImageSharp.fluid}
                        ></Img>
                      </Link>
                      <Card.Text className="cardText">
                        <span className={becomeAMemberStyle.aboutContent}>
                          Members get full access to the following audio guided
                          MOM meditations. Find a quiet spot and meditate when
                          you need it or on a regular schedule!{" "}
                        </span>
                      </Card.Text>
                      <ul className={becomeAMemberStyle.list}>
                        <li>
                          <Link to="/membership-preview">
                            For when you’re feeling down
                          </Link>
                        </li>
                        <li>
                          <Link to="/membership-preview">
                            To calm your mind
                          </Link>
                        </li>
                        <li>
                          <Link to="/membership-preview">
                            To prevent exhaustion
                          </Link>
                        </li>
                        <li>
                          <Link to="/membership-preview">
                            For fear of the future
                          </Link>
                        </li>
                        <li>
                          <Link to="/membership-preview">
                            To control your mood swings
                          </Link>
                        </li>
                      </ul>
                    </Card.Body>
                    <Card.Footer className={becomeAMemberStyle.cardFooter}>
                      <div className={becomeAMemberStyle.previewButtonContent}>
                        <Button
                          className={[
                            globalStyle.btnPrimary,
                            globalStyle.submitButtonOrange,
                            becomeAMemberStyle.previewButton,
                          ]}
                          href="/membership-preview"
                        >
                          Preview
                        </Button>
                      </div>
                    </Card.Footer>
                  </Card>

                  <Card className={becomeAMemberStyle.cardContent}>
                    <Card.Body>
                      <Card.Title className="blogTitle">
                        <h5 className={becomeAMemberStyle.benefitsTitle}>
                          Baby Massage
                        </h5>
                      </Card.Title>
                      <Link to={"/membership-preview"}>
                        <Img
                          fluid={data.babyMassage.childImageSharp.fluid}
                        ></Img>
                      </Link>
                      <Card.Text className="cardText">
                        <span className={becomeAMemberStyle.aboutContent}>
                          Members get full access to the following online baby
                          massage instructional videos and classes. Have fun
                          bonding with your joy!
                        </span>
                      </Card.Text>
                      <ul className={becomeAMemberStyle.list}>
                        <li>
                          <Link to={"/membership-preview"}>
                            About baby massage and massaging the legs & feet
                          </Link>
                        </li>
                        <li>
                          <Link to={"/membership-preview"}>
                            Massaging baby's tummy and chest & arms{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to={"/membership-preview"}>
                            Massaging baby's face & back{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to={"/membership-preview"}>
                            Bringing it all together{" "}
                          </Link>
                        </li>
                      </ul>
                    </Card.Body>
                    <Card.Footer className={becomeAMemberStyle.cardFooter}>
                      <div className={becomeAMemberStyle.previewButtonContent}>
                        <Button
                          className={[
                            globalStyle.btnPrimary,
                            globalStyle.submitButtonOrange,
                            becomeAMemberStyle.previewButton,
                          ]}
                          href="/membership-preview"
                        >
                          Preview
                        </Button>
                      </div>
                    </Card.Footer>
                  </Card>
                </CardDeck>
              </Col>
            </Row>

            <hr />

            <Row>
              <Col md={12} sm={12} xs={12}>
                <CardDeck>

                  <Card className={becomeAMemberStyle.cardContent}>
                    <Card.Body>
                      <Card.Title className="blogTitle">
                        <h5 className={becomeAMemberStyle.benefitsTitle}>
                          Expert Interviews
                        </h5>
                      </Card.Title>
                      <Link to={"/membership-preview"}>
                        <Img
                          fluid={data.interviewsPhoto.childImageSharp.fluid}
                        ></Img>
                      </Link>
                      <Card.Text className="cardText">
                        <span className={becomeAMemberStyle.aboutContent}>
                          Members get full access to interviews with experts in
                          the field!
                        </span>
                      </Card.Text>
                      <ul className={becomeAMemberStyle.list}>
                        <li>
                          <Link to={"/membership-preview"}>
                            Breastfeeding tips and strategies
                          </Link>
                        </li>
                        <li>
                          <Link to={"/membership-preview"}>
                            Maternal mental health discussion
                          </Link>
                        </li>
                        <li>
                          <Link to={"/membership-preview"}>
                            Baby and toddler sleep best practices
                          </Link>
                        </li>
                        <li>
                          <Link to={"/membership-preview"} className={becomeAMemberStyle.linkToTheExpert}>
                            See all interviews included
                          </Link>
                        </li>
                      </ul>
                    </Card.Body>
                    <Card.Footer className={becomeAMemberStyle.cardFooter}>
                      <div className={becomeAMemberStyle.previewButtonContent}>
                        <Button
                          className={[
                            globalStyle.btnPrimary,
                            globalStyle.submitButtonOrange,
                            becomeAMemberStyle.previewButton,
                          ]}
                          href="/membership-preview"
                        >
                          Preview
                        </Button>
                      </div>
                    </Card.Footer>
                  </Card>
                  <Card className={becomeAMemberStyle.cardContent}>
                    <Card.Body>
                      <Card.Title className="blogTitle">
                        <h5 className={becomeAMemberStyle.benefitsTitle}>
                          Event Discounts + MOM Tank
                        </h5>
                      </Card.Title>
                      <Link to={"/membership-preview"}>
                        <Img
                          fluid={data.discountEvents.childImageSharp.fluid}
                        ></Img>
                      </Link>
                      <Card.Text className="cardText">
                        <span className={becomeAMemberStyle.aboutContent}>
                          We will be publishing events and retreats later this
                          year as things calm down. All members get a MOM
                          racerback tank top.
                        </span>
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className={becomeAMemberStyle.cardFooter}>
                      <div className={becomeAMemberStyle.previewButtonContent}>
                        <Button
                          className={[
                            globalStyle.btnPrimary,
                            globalStyle.submitButtonOrange,
                            becomeAMemberStyle.previewButton,
                          ]}
                          href="/membership-preview"
                        >
                          Preview
                        </Button>
                      </div>
                    </Card.Footer>
                  </Card>
                </CardDeck>
              </Col>
            </Row>

            <hr />

            <Row>
              <Col md={12} sm={12} xs={12}>
                <CardDeck>
                  <Card className={becomeAMemberStyle.cardContent}>
                    <Card.Body>
                      <Card.Title className="blogTitle">
                        <h5 className={becomeAMemberStyle.benefitsTitle}>
                          Health &amp; Wellness
                        </h5>
                      </Card.Title>
                      <Link to={"/membership-preview"}>
                        <Img fluid={data.wellnessPhoto.childImageSharp.fluid}></Img>
                      </Link>
                      <Card.Text className="cardText">
                        <span className={becomeAMemberStyle.aboutContent}>
			                    Get access to workbooks, articles and guides to aid healing and balance in your busy life. Plus,
                  	      all Moms on Maternity Club Members receive one complimentary Exploration Call ($145 value) with one of
		  	                  our professional coaches.
                        </span>
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className={becomeAMemberStyle.cardFooter}>
                      <div className={becomeAMemberStyle.previewButtonContent}>
                        <Button
                          className={[
                            globalStyle.btnPrimary,
                            globalStyle.submitButtonOrange,
                            becomeAMemberStyle.previewButton,
                          ]}
                          href="/membership-preview"
                        >
                          Preview
                        </Button>
                      </div>
                    </Card.Footer>
                  </Card>


                </CardDeck>
              </Col>
            </Row>

            <hr />

            <Row>
              <Col className={globalStyle.boldStyle}>
                This Content is made possible by our partners:
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={6} xs={12}>
                <a
                  href="https://bambinoandi.co.uk/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img fluid={data.massageLogo.childImageSharp.fluid}></Img>
                </a>
              </Col>
              <Col md={6} sm={6} xs={12}>
                <a
                  href="https://www.doyogawithme.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img fluid={data.yogaLogo.childImageSharp.fluid}></Img>
                </a>
              </Col>
            </Row>

            <hr />

            {!(emailValueFromLocalStorage && last4ValueFromLocalStorage) && (
              <>
                <Row className={becomeAMemberStyle.loginSectionFromBottom}>
                  <Col>
                    <Row>
                      <Col md={6}>
                        <p className={globalStyle.boldStyle}>
                          Already a member?
                        </p>
                        <Button
                          className={[
                            globalStyle.btnPrimary,
                            becomeAMemberStyle.goldButton,
                            becomeAMemberStyle.signInButton
                          ]}
                          onClick={() => {
                            setShowLoginFormContent({
                              showTop: false,
                              showBottom: !showLoginFormContent.showBottom,
                            })
                            setShowSignUpForm({
                              showTop: false,
                              showBottom: false,
                            })
                            setShowErrorAlert({
                              showTop: false,
                              showBottom: false,
                            })
                            setShowLoginAlert({
                              showTop: false,
                              showBottom: false,
                            })
                            setShowSizeErrorAlert({
                              showTop: false,
                              showBottom: false
                            })
                            setShowEmailErrorAlert({
                              showTop: false,
                              showBottom: false
                            })
                          }}
                        >
                          SIGN IN
                        </Button>
                      </Col>
                      <Col md={6}>
                        <p className={becomeAMemberStyle.promoChoice}>OR</p>
                        <Button
                          className={[
                            globalStyle.btnPrimary,
                            becomeAMemberStyle.goldButton,
                          ]}
                          onClick={() => {
                            setShowLoginFormContent({
                              showTop: false,
                              showBottom: false,
                            })
                            setShowSignUpForm({
                              showTop: false,
                              showBottom: !showSignUpForm.showBottom,
                            })
                            setShowLoginAlert({
                              showTop: false,
                              showBottom: false,
                            })
                            setShowSizeErrorAlert({
                              showTop: false,
                              showBottom: false
                            })
                            setShowEmailErrorAlert({
                              showTop: false,
                              showBottom: false
                            })
                          }}
                        >
                          JOIN US AND GET IMMEDIATE ACCESS
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}

            <Row className={becomeAMemberStyle.formContent}>
              {showLoginFormContent.showBottom && (
                <Form
                  onSubmit={_handleSubmit}
                  name="signin"
                  className={becomeAMemberStyle.formContent}
                >
                  <Form.Row>
                    <Col xs={12} md={12} sm={12}>
                      <Form.Group as={Col} controlId="formEmail">
                        <Form.Control
                          size="sm"
                          value={formState.email}
                          name="email"
                          type="text"
                          placeholder="Please enter your email"
                          onChange={handleFormChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={12} sm={12}>
                      <Form.Group as={Col} controlId="formLast4">
                        <Form.Control
                          size="sm"
                          value={formState.last4}
                          name="last4"
                          type="text"
                          placeholder="Last 4 digits of your payment card"
                          onChange={handleFormChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={12} sm={12}>
                      <Button
                        type="submit"
                        className={[
                          formStyle.btnPrimary,
                          formStyle.submitButtonOrange,
                          becomeAMemberStyle.submitButton,
                        ]}
                      >
                        Log in
                      </Button>
                    </Col>
                  </Form.Row>
                </Form>
              )}
            </Row>

            {showSignUpForm.showBottom && (
              <Form onSubmit={handleSignUpSubmit} name="register">
                <Form.Row>
                  <Col xs={12} md={12} sm={12}>
                    <Form.Group as={Col} controlId="formLast4" className={becomeAMemberStyle.registerFormGroup}>
                      <Form.Control as="select" onChange={handleSizeSelection} value={formState.size}
                                    placeholder="Select your MOM Tank Size" name="size">
                        <option className="defaultValue" value="">Select your MOM Tank size</option>
                        <option value="S">S</option>
                        <option value="M">M</option>
                        <option value="L">L</option>
                        <option value="XL">XL</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={12} sm={12}>
                    <Form.Group as={Col} controlId="formEmail" className={becomeAMemberStyle.registerFormGroup}>
                      <Form.Control
                        size="sm"
                        value={formState.email}
                        name="email"
                        type="text"
                        placeholder="Please enter your email"
                        onChange={handleSignUpFormChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={12} sm={12} className={becomeAMemberStyle.checkoutButton}>
                    <MembershipSkuCard quantity={formState.quantity}
                                       email={formState.email}
                                       size={formState.size}
                                       sku={sku}
                                       beforeRedirect={validateForm}
                                       showStripeErrorMessage={showStripeErrorMessage}
                                       path="/become-a-member"
                    />
                  </Col>
                </Form.Row>
              </Form>
            )}

            {
              showSizeError.showBottom &&
              <Row>
                <Col>
                  <Alert className={"mt-2"} variant="danger" onClose={() => setShowSizeErrorAlert({showBottom: false})} dismissible
                  >Please select your MOM Tank size!</Alert>
                </Col>
              </Row>
            }

            {
              showEmailError.showBottom &&
              <Row>
                <Col>
                  <Alert className={"mt-2"} variant="danger" onClose={() => setShowEmailErrorAlert({showBottom: false})} dismissible
                  >Please enter your email address!</Alert>
                </Col>
              </Row>
            }

            {showError.showBottom && (
              <Row className={becomeAMemberStyle.showStripeError}>
                <Alert
                  variant="danger"
                  onClose={() => setShowErrorAlert({ showBottom: false })}
                  dismissible
                >
                  Please fill the {showError.onField.toLowerCase()} field!
                </Alert>
              </Row>
            )}
            {stripeShowErrorBottomForm.show && (
              <Row className={becomeAMemberStyle.showStripeError}>
                <Alert
                  variant="danger"
                  onClose={() => setStripeShowErrorBottomFormAlert({ show: false })}
                  dismissible
                >
                  {stripeShowErrorBottomForm.message}
                </Alert>
              </Row>
            )}

            {stripeShowSuccessMessage.show && (
              <Row className={becomeAMemberStyle.showStripeError}>
                <Alert
                  variant="success"
                  onClose={() => setStripeShowSuccessAlert({ show: false })}
                  dismissible
                >
                  {stripeShowSuccessMessage.message}
                </Alert>
              </Row>
            )}

            {mailchimpAddMemberShowError.show && (
              <Row className={becomeAMemberStyle.showMemberExistsAlert}>
                <Alert
                  variant="danger"
                  onClose={() =>
                    setMailchimpAddMemberShowErrorAlert({ show: false })
                  }
                  dismissible
                >
                  Please try again!
                </Alert>
              </Row>
            )}

            {memberExistsAlert.show && (
              <Row className={becomeAMemberStyle.showMemberExistsAlert}>
                <Alert
                  variant="primary"
                  onClose={() => setMemberExistsAlert({ show: false })}
                  dismissible
                >
                  {memberExistsAlert.message}
                </Alert>
              </Row>
            )}

            {mailchimpAddMemberShowSuccessMessage.show && (
              <Row className={becomeAMemberStyle.showMemberExistsAlert}>
                <Alert
                  variant="primary"
                  onClose={() =>
                    setMailchimpAddMemberShowSuccessMessageAlert({
                      show: false,
                    })
                  }
                  dismissible
                >
                  Thank you for subscribing! You are a MOM Member!
                </Alert>
              </Row>
            )}

            {/*{showSignUpForm.showBottom && (*/}
            {/*  <Row*/}
            {/*    className={becomeAMemberStyle.openPaywallContent}*/}
            {/*    style={{ position: "relative" }}*/}
            {/*  >*/}
            {/*    <OpenPaywallN*/}
            {/*      className={becomeAMemberStyle.openPaywallClass}*/}
            {/*      action={openPaywallNConfig.action}*/}
            {/*      provider="stripe"*/}
            {/*      accessToken={openPaywallNConfig.accessToken}*/}
            {/*      cost={openPaywallNConfig.cost}*/}
            {/*      wallButtonValue="ACCESS PREMIUM CONTENT"*/}
            {/*      buyButtonValue="BUY FOR"*/}
            {/*      VIPMembersUrl={VIPMembersUrl}*/}
            {/*      onPaymentCompleted={onPaymentCompleted}*/}
            {/*      showErrorMessage={showErrorMessage}*/}
            {/*      showSuccessMessage={showSuccessMessage}*/}
            {/*    ></OpenPaywallN>*/}
            {/*  </Row>*/}
            {/*)}*/}

            {/*{showLoginAlert.showBottom && (*/}
            {/*  <Row className={becomeAMemberStyle.showStripeError}>*/}
            {/*    <Alert*/}
            {/*      variant="danger"*/}
            {/*      onClose={() => setShowLoginAlert({ showBottom: false })}*/}
            {/*      dismissible*/}
            {/*    >*/}
            {/*      {showLoginAlert.message}*/}
            {/*    </Alert>*/}
            {/*  </Row>*/}
            {/*)}*/}

            {/*{showError.showBottom && (*/}
            {/*  <Row*/}
            {/*    className={becomeAMemberStyle.showStripeError}*/}
            {/*    style={{ marginTop: "1rem" }}*/}
            {/*  >*/}
            {/*    <Alert*/}
            {/*      variant="danger"*/}
            {/*      onClose={() => setShowErrorAlert({ showBottom: false })}*/}
            {/*      dismissible*/}
            {/*    >*/}
            {/*      {showError.onField} field is required*/}
            {/*    </Alert>*/}
            {/*  </Row>*/}
            {/*)}*/}

            {/*{stripeShowErrorBottomForm.show && (*/}
            {/*  <Row className={becomeAMemberStyle.showStripeError}>*/}
            {/*    <Alert*/}
            {/*      variant="danger"*/}
            {/*      onClose={() =>*/}
            {/*        setStripeShowErrorBottomFormAlert({ show: false })*/}
            {/*      }*/}
            {/*      dismissible*/}
            {/*    >*/}
            {/*      {stripeShowErrorBottomForm.message}*/}
            {/*    </Alert>*/}
            {/*  </Row>*/}
            {/*)}*/}

            {/*{stripeShowSuccessMessageBottomForm.show && (*/}
            {/*  <Row className={becomeAMemberStyle.showStripeError}>*/}
            {/*    <Alert*/}
            {/*      variant="success"*/}
            {/*      onClose={() =>*/}
            {/*        setStripeShowSuccessAlertBottomForm({ show: false })*/}
            {/*      }*/}
            {/*      dismissible*/}
            {/*    >*/}
            {/*      {stripeShowSuccessMessageBottomForm.message}*/}
            {/*    </Alert>*/}
            {/*  </Row>*/}
            {/*)}*/}

            {/*{mailchimpAddMemberShowError.show && (*/}
            {/*  <Row className={becomeAMemberStyle.showMemberExistsAlert}>*/}
            {/*    <Alert*/}
            {/*      variant="danger"*/}
            {/*      onClose={() =>*/}
            {/*        setMailchimpAddMemberShowErrorAlert({ show: false })*/}
            {/*      }*/}
            {/*      dismissible*/}
            {/*    >*/}
            {/*      {mailchimpAddMemberShowError.message}. Please try again!*/}
            {/*    </Alert>*/}
            {/*  </Row>*/}
            {/*)}*/}

            {/*{memberExistsAlert.show && (*/}
            {/*  <Row className={becomeAMemberStyle.showMemberExistsAlert}>*/}
            {/*    <Alert*/}
            {/*      variant="primary"*/}
            {/*      onClose={() => setMemberExistsAlert({ show: false })}*/}
            {/*      dismissible*/}
            {/*    >*/}
            {/*      {memberExistsAlert.message}*/}
            {/*    </Alert>*/}
            {/*  </Row>*/}
            {/*)}*/}

            {/*{mailchimpAddMemberShowSuccessMessage.show && (*/}
            {/*  <Row className={becomeAMemberStyle.showMemberExistsAlert}>*/}
            {/*    <Alert*/}
            {/*      variant="primary"*/}
            {/*      onClose={() =>*/}
            {/*        setMailchimpAddMemberShowSuccessMessageAlert({*/}
            {/*          show: false,*/}
            {/*        })*/}
            {/*      }*/}
            {/*      dismissible*/}
            {/*    >*/}
            {/*      Thank you for subscribing! You are a MOM Member!*/}
            {/*    </Alert>*/}
            {/*  </Row>*/}
            {/*)}*/}
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    prenatalYoga: file(relativePath: { eq: "yoga/prenatal-yoga2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    postpartumYoga: file(relativePath: { eq: "yoga/postpartum-yoga2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    momMeditations: file(relativePath: { eq: "yoga/mom-meditations2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    babyMassage: file(relativePath: { eq: "massage/baby-massage2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wellnessPhoto: file(
      relativePath: { eq: "wellness/thiago-cerqueira-Wr3HGvx_RSM-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    interviewsPhoto: file(
      relativePath: { eq: "interviews/interviews-resized.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    membershipBenefitsVideo: allVimeo(
      filter: { tags: { elemMatch: { tag: { eq: "membership-benefits" } } } }
    ) {
      edges {
        node {
          id
          name
          link
          tags {
            name
          }
          embed {
            html
          }
        }
      }
    }
    massageLogo: file(relativePath: { eq: "massage/bambino-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    yogaLogo: file(relativePath: { eq: "yoga/do-yoga-with-me-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    discountEvents: file(
      relativePath: { eq: "discounts/discounts-resized.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    skus: allStripeSku {
      edges {
        node {
          id
          price
          attributes {
            size
          }
          product {
            id
            name
          }
        }
      }
    }
  }
`

export default BecomeAMamberPage
